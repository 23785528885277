// author:马云阳；description:众惠-公募-填写转换信息2
<template>
    <section class="PublicFundProcess">
        <!-- <NavRightSide/> -->
        <div class="publicfundprocess_cont">
            <a @click="toGos" class="title_">
             <span class="iconfont">&#xe665;</span> {{funddatas.funddatas.fund.fundName||''}}
            </a>
            <div class="side_cont">
              <ProgressBar :sideData="side"/>
            </div>
            <div class="solids_"></div>
           <div class="card_">
              <img src="@/static/img/my/PasswordAlterSuccess.png" class="true_" alt="">
              <div class="true_ti">
                <div class="tis_">申请受理成功<br/>本交易支持{{txts}}前撤回</div>
                <div class="timers_">{{newdates||''}}</div>
              </div>
              <div class="card_timers">
                <div class="l_ti">份额</div>
                <div class="r_timers"><span style="color:#D43F3F;font-weight: 400;">{{funddatas.funddatas.num||''}}</span>份</div>
              </div>
              <div class="card_timers">
                <div class="l_ti">卖出</div>
                <div class="r_timers">{{funddatas.funddatas.fund.fundName||''}}</div>
              </div>
              <div class="card_timers">
                <div class="l_ti">交易</div>
                <div class="r_timers"><img style="width:26px;height:24px;" src="@/static/img/my/bank_icon.png" alt="">{{funddatas.funddatas.type==1?'惠金宝支付':'银行卡支付'}}</div>
              </div>
              <div class="card_timers">
                <div class="l_ti">转出方式</div>
                <div class="r_timers" style="color:#25293D;font-weight:400;"><img style="width:28px;height:28px;" v-if="funddatas.funddatas.type==2" :src="funddatas.funddatas.banklist.logoUrl" alt="">{{funddatas.funddatas.type==1?'惠金宝':funddatas.funddatas.banklist.bankName}}<span v-if="funddatas.funddatas.type==2" style="color:#A5A5A5;">({{banknums}})</span></div>
              </div>
              <div class="card_timers_">
                <div class="l_ti">预计进度</div>
                <div class="r_timers">
                  <div class="top_">
                    <div class="imgs_ img_rudis">
                      <!-- <img src="" alt=""> -->
                    </div>
                    <div class="top_name_">
                      {{Ns}} 预计份额确认
                      <!-- <span>161725</span> -->
                    </div>
                  </div>
                  <div class="center_">
                     <div class="imgs_">
                      <span style="color:#EABF96;font-size:26px;" class="iconfont">&#xe68e;</span>
                    </div>
                    <!-- <div class="top_name_">
                      转出份额 
                      <span>880.0份</span>
                    </div> -->
                  </div>
                  <div class="footer_">
                     <div class="imgs_ img_rudis">
                      <!-- <img src="" alt=""> -->
                    </div>
                    <div class="top_name_">
                      {{Es}} 查看盈亏
                    </div>
                  </div>
                </div>
              </div>
              <a @click="toGos" class="topage_">
                返回基金持仓
              </a>
          </div>
          
        </div>
    </section>
</template>
<script>
// import NavRightSide from "@/components/NavRightSide";
import ProgressBar from "@/components/ProgressBar";

export default {
  name: "SellOutFundfour",
  components: { ProgressBar },
  data() {
    return {
      side: [
        { title: "选择卖出基金", type: 1 },
        { title: "填写卖出信息", type: 1 },
        { title: "输入卖出密码", type: 1 },
        { title: "交易结果", type: 2 }
      ],
      funddatas: {},
      newdates: "--",
      txts: "15点",
      banknums: "",
      Ns: "-",
      Es: "-"
    };
  },
  methods: {
    forgetPoss() {
      this.$router.push('/myFavors/accountManage/safetyCentre')
    },
    topeges() {
      console.log("执行下一步");
    },
    getnewdata(a) {
      let date = a;
      let yy = date.getFullYear();
      let mm = date.getMonth() + 1;
      let dd = date.getDate();
      let hh = date.getHours();
      let mf =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      let ss =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      let jjj = yy + "-" + mm + "-" + dd + " " + "15:00:00";
      let aaa = new Date(jjj);
      this.newdates = yy + "-" + mm + "-" + dd + " " + hh + ":" + mf + ":" + ss;
      let bbb = new Date(this.newdates);
      if (bbb >= aaa) {
        this.txts = "明天15点(节假日顺延)";
        let dds = date.getDate() + 2;
        let ees = date.getDate() + 3;
        this.Ns = yy + "-" + mm + "-" + dds;
        this.Es = yy + "-" + mm + "-" + ees;
      } else {
        this.txts = "15点";
        let dds = date.getDate() + 1;
        let ees = date.getDate() + 2;
        this.Ns = yy + "-" + mm + "-" + dds;
        this.Es = yy + "-" + mm + "-" + ees;
      }
    },
    toGos() {
      this.$router.go(-3);
    }
  },
  created() {
    this.funddatas = this.$route.params.okey;
    console.log('as',this.funddatas)
    this.banknums = this.funddatas.funddatas.banklist.bankAccount.slice(-4);
    this.getnewdata(this.funddatas.dates);
  }
};
</script>
<style lang="less" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
::-webkit-input-placeholder {
  color: #bdc0cb;
}
::-moz-placeholder {
  color: #bdc0cb;
}

/* firefox 19+ */
:-ms-input-placeholder {
  color: #bdc0cb;
}

/* ie */
input:-moz-placeholder {
  color: #bdc0cb;
}

.PublicFundProcess {
  min-height: 600px;
  display: flex;
  background: #f1f1f1;
  .publicfundprocess_cont {
  padding-top: 50px;
    background: #fff;
    min-height: 1290px;
    width: 930px;
    .title_ {
      padding: 40px 40px 0px 40px;
      height: 60px;
      font-size: 20px;
      color: #1f1f1f;
    }
    .side_cont {
      height: 146px;
      padding: 0px 0px 0px 80px;
      display: flex;
      align-items: center;
    }
    .solids_ {
      height: 2px;
      background: linear-gradient(270deg, #eabf96 0%, #f7d9b7);
      box-shadow: 0px 4px 12px 0px #edf1f7;
    }
    .card_ {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .true_ {
        width: 120px;
        height: 120px;
        margin-bottom: 30px;
      }
      .true_ti {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 20px;
        .tis_ {
          font-size: 20px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
          font-weight: 400;
          color: #25293d;
          text-align: center;
        }
        .timers_ {
          font-size: 16px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
          font-weight: 400;
          color: #a5a5a5;
          margin-top: 14px;
        }
      }
      .cards_tis {
        width: 646px;
        height: 64px;
        background: rgba(255, 245, 234, 0.5);
        border-radius: 2px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        span {
          font-size: 12px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI-Light;
          font-weight: 300;
          color: #ce9b63;
          text-align: center;
        }
      }
      .card_timers {
        margin-top: 20px;
        display: flex;
        width: 100%;
        align-items: center;
        .l_ti {
          width: 42%;
          margin-right: 2%;
          font-size: 16px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
          font-weight: 400;
          text-align: RIGHT;
          color: #a5a5a5;
        }
        .r_timers {
          font-size: 18px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI-Light;
          font-weight: 300;
          text-align: left;
          color: #25293d;
          display: flex;
          align-items: center;
          img {
            margin-right: 8px;
          }
        }
      }
      .card_timers_ {
        margin-top: 24px;
        display: flex;
        width: 100%;
        .l_ti {
          width: 42%;
          margin-right: 2%;
          font-size: 16px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
          font-weight: 400;
          text-align: RIGHT;
          color: #a5a5a5;
        }
        .r_timers {
          .top_ {
            display: flex;
            align-items: center;
            // .imgs_ {
            //   width: 28px;
            //   height: 28px;
            //   img {
            //     width: 100%;
            //     height: 100%;
            //   }
            // }
            .img_rudis {
              border: 2px solid #eabf96;
              // border-image: linear-gradient(134deg, #f7d9b7 0%, #eabf96 100%)
              //   1.6399157047271729 1.6399157047271729;
              border-radius: 50% !important;
              width: 16px !important;
              height: 16px !important;
            }
            .top_name_ {
              font-size: 18px;
              font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
              font-weight: 400;
              color: #25293d;
              margin-left: 8px;
              span {
                color: #a5a5a5;
              }
            }
          }
          .center_ {
            display: flex;
            margin-top: 30px;
            // align-items: center;
            .imgs_ {
              width: 28px;
              height: 28px;
              text-align: center;
              img {
                width: 7px;
                height: 25px;
              }
            }
            .top_name_ {
              font-size: 16px;
              font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
              font-weight: 400;
              color: #a5a5a5;
              margin-left: 8px;
              span {
                font-size: 18px;
                font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
                font-weight: 400;
                color: #e45247;
              }
            }
          }
          .footer_ {
            display: flex;
            align-items: center;
            margin-top: 30px;
            .imgs_ {
              width: 28px;
              height: 28px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .img_rudis {
              border: 2px solid #eabf96;
              // border-image: linear-gradient(134deg, #f7d9b7 0%, #eabf96 100%)
              //   1.6399157047271729 1.6399157047271729;
              border-radius: 50% !important;
              width: 16px !important;
              height: 16px !important;
            }
            .top_name_ {
              font-size: 18px;
              font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
              font-weight: 400;
              color: #25293d;
              margin-left: 8px;
              span {
                color: #a5a5a5;
              }
            }
          }
        }
      }
      .topage_ {
        width: 240px;
        height: 48px;
        background: linear-gradient(101deg, #f7d9b7 0%, #eabf96 100%);
        border-radius: 2px;
        text-align: center;
        line-height: 48px;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #b88141;
        margin-top: 24px;
      }
    }
  }
}
</style>
